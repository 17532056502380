<template>
    <div>
        <el-tabs>
            <!-- <el-tab-pane label="第一/二季度证书">
                <el-button type="primary" @click="exportPDF('showTotal')" size="medium" style="margin:5px;">导出PDF</el-button>
                

                <div id="showTotal">
                    <div v-for="(item,index) in event_awards" :key="index" >
                       
                        <div v-if="item.award_saiji_num ? false : true" class="certContainer4">

                            <img
                            src="../../assets/felc_cer.png"
                            class="model"
                            />
                            
                            <div class="ziZm">兹证明</div>

                            
                            <div class="event">{{ item.project_name}}</div>

                            <div class="projectName">{{ item.application_event }}</div>

                            <div class="award">{{ item.award_show_lable }}</div>

                            <div class="awardPh">{{'('+ item.percent_lable + ')'}}</div>

                            <div class="student">
                            <span class="name">{{ item.student.student_lastName + item.student.student_givenName }}</span>
                            </div>
                            <div class="school">{{ item.account.account_name }}</div>

                            <div class="email">{{ item.student.student_email }}</div>

                            <div class="rhZm">荣获</div>

                            <img class="saiquImg" v-if="item.group_name == '92赛区'" src="../../assets/felc-92赛区.png">
                            <img class="saiquImg" v-if="item.group_name == 'FE赛区'" src="../../assets/felc-FE赛区.png">
                            <img class="saiquImg" v-if="item.group_name == '标准赛区'" src="../../assets/felc-标准赛区.png">
                            <img class="saiquImg" v-if="item.group_name == 'BFA赛区'" src="../../assets/felc-BFA赛区.png">
                            <img class="saiquImg" v-if="item.group_name == '国际赛区'" src="../../assets/felc-国际赛区.png">
                            <img class="saiquImg" v-if="item.group_name == '职教赛区'" src="../../assets/felc-职教赛区.png">



                            <div class="tefaLab">特发此证，以资鼓励。</div>

                            <div class="timeLab">{{'颁发日期：' + item.show_time}}</div>

                        </div>

                    </div>
                </div>
            </el-tab-pane> -->

          
            <!-- <el-tab-pane label="第三季度证书">

                <el-button type="primary" @click="exportPDF('showTranscript')" size="medium">导出pdf</el-button>

                <div id="showTranscript" v-if="project_code == '23-24FELC' && type != 'adviser'">

                    <div v-for="(item,index) in event_awards" :key="index" >


                    <div v-if="item.award_saiji_num ? true : false" class="certContainerFelc">

                        <img
                            src="../../assets/felc_cer_new.jpg"
                            class="model"
                            />

                        <div class="school">{{ item.account.account_name }}</div>
                        <div class="student">
                        <span class="name">{{ item.student.student_lastName + item.student.student_givenName }}</span>
                        </div>
                        <div class="email">{{ item.student.student_email }}</div>


                        <div class="tefaLab">FELC未来企业菁英</div>

                        <div class="projectName">{{ item.award_show_project_name }}</div>

                        <div class="event">{{ item.application_event }}</div>

                        

                        <div class="award">{{ item.award_show_lable }}</div>

                        <div class="awardPh">{{'('+ item.percent_lable + ')'}}</div>

                        

                        

                        <img class="saiquImg" v-if="item.group_name == '92赛区'" src="../../assets/felc-92赛区.png">
                        <img class="saiquImg" v-if="item.group_name == 'FE赛区'" src="../../assets/felc-FE赛区.png">
                        <img class="saiquImg" v-if="item.group_name == '标准赛区'" src="../../assets/felc-标准赛区.png">
                        <img class="saiquImg" v-if="item.group_name == 'BFA赛区'" src="../../assets/felc-BFA赛区.png">
                        <img class="saiquImg" v-if="item.group_name == '国际赛区'" src="../../assets/felc-国际赛区.png">
                        <img class="saiquImg" v-if="item.group_name == '职教赛区'" src="../../assets/felc-职教赛区.png">


                        <div class="timeLab">{{item.show_time}}</div>


                    </div>


                    </div>


                </div>

            </el-tab-pane> -->

            <el-tab-pane label="精英赛证书">

              <el-button type="primary" @click="exportPDF('showTranscript')" size="medium">导出pdf</el-button>

              <div id="showTranscript">

                  <div v-for="(item,index) in event_awards" :key="index" >


                  <div class="certContainerFelc">

                      <img
                          src="../../assets/felc_cer_new.jpg"
                          class="model"
                          />

                      <div class="school">{{ item.account.account_name }}</div>
                      <div class="student">
                      <span class="name">{{ item.student.student_lastName + item.student.student_givenName }}</span>
                      </div>
                      <div class="email">{{ item.student.student_email }}</div>


                      <div class="tefaLab">23-24学年FELC未来企业菁英</div>

                      <div class="projectName">全国高校商科学术竞赛  全国高校企业技能竞赛</div>

                      <div class="event">{{ item.application_event }}</div>

                      

                      <div class="award">{{ item.award_show_lable }}</div>

                      <!-- <div class="awardPh">{{'('+ item.percent_lable + ')'}}</div> -->

                      

                      

                      <img class="saiquImg" v-if="item.group_name == '92赛区'" src="../../assets/felc-92赛区.png">
                      <img class="saiquImg" v-if="item.group_name == 'FE赛区'" src="../../assets/felc-FE赛区.png">
                      <img class="saiquImg" v-if="item.group_name == '标准赛区'" src="../../assets/felc-标准赛区.png">
                      <img class="saiquImg" v-if="item.group_name == 'BFA赛区'" src="../../assets/felc-BFA赛区.png">
                      <img class="saiquImg" v-if="item.group_name == '国际赛区'" src="../../assets/felc-国际赛区.png">
                      <img class="saiquImg" v-if="item.group_name == '职教赛区'" src="../../assets/felc-职教赛区.png">


                      <div class="timeLab">2024年8月9日</div>


                  </div>


                  </div>


              </div>

              </el-tab-pane>
           
            <!-- <el-tab-pane label="成绩单">
                <el-button type="primary" @click="exportPDF('showTranscript')" size="medium" style="margin:5px;">导出PDF</el-button>

                <div id="showTranscript">
                    <div v-for="(item,index) in transcripts" :key="index" class="transcriptContainer" style="margin-bottom:10px;">
                        <img src="../../assets/transcript_felc.jpg" class="model_p"/>
                        <div class="name">{{upfirstName(item.student_info.student_givenName_pinyin) + ' ' + upfirstName(item.student_info.student_lastName_pinyin) + ' ' + item.student_info.student_lastName + ' ' + item.student_info.student_givenName}}</div>
                        <div class="info">
                            <div>
                                <span class="tag">邮箱: </span>
                                <span class="content">{{item.student_info.student_email}}</span> | 
                                <span class="tag">性别: </span>
                                <span class="content">{{item.student_info.student_gender == '男'? '男' : '女'}}</span> | 
                                <span class="tag">预计毕业年份: </span>
                                <span class="content">{{item.student_info.student_graduation}}</span>
                            </div>
                            <div style="margin-top:6px">
                                <span class="tag">学校: </span>
                                <span class="content">{{item.account_info.account_name}}</span>
                            </div>
                        </div>
                        
                        <div class="score1" >
                            <div  v-for="score in item.score_info" :key="score._id.$id">
                                <div  v-if="score.score_type == 'personal'" style="margin-bottom:20px;">
                                    <div class="title">{{score.application_event + ' - ' + '客观笔试'}}</div>
                                    <div style="margin-top:5px">分数:<span>{{score.total_score}}</span>/{{score.full_score}} ></div>  
                                </div>
                            </div>
                        </div>

                        <div class="time1">{{'2023年4月3日'}}</div>


                    </div>
                </div>
            </el-tab-pane> -->
        </el-tabs>
    </div>
</template>

<script>
import { getStudentAwards,getStudentTranscripts} from '../../api/index.js'
import {getUserId,getProjectCode} from '../../utils/store'
import {formatDate} from '../../utils/date'

export default {
  name: 'awardfbla',
  data () {
    return {
        project_code:getProjectCode(),
        total_awards: [],
        event_awards: [],
        completes: [],
        transcripts:[],
        type:'all',
        id:'',
        award_create_time:'2022年12月10日',
        award_type_label_arr:{
            'personal total':'Individual Total Score Rank',
            'event rank':'Individual Event Rank',
            'event team rank':'Team Event Rank'
        },
        direction:1

    }
  },
  mounted () {

    this.type = this.$route.query.type
    this.id = this.$route.query.id

    this.fetchData()
  },
  methods: {
    fetchData () {

        console.log(1111,getProjectCode())
        
        getStudentAwards(getProjectCode(),getUserId()).then(res => {
            if (res.data.code === 0) {
            this.total_awards = res.data.data.filter(
                (item) => item.award_type === 'personal total'
            )
            this.event_awards = res.data.data.filter(
                (item) => item.award_type === 'event rank' || item.award_type === 'event team rank'
            )
            }
        })
        //   getCompletes().then(res => {
        //     if (res.data.code === 0) {
        //       this.completes = res.data.data
        //     }
        //   })
        getStudentTranscripts(getProjectCode(),getUserId()).then(res => {
            console.log(res)
            if (res.data.code === 0) {
                this.transcripts = res.data.data
            };

        })
    
      
    },
    formatCreateTime(create_time) {
      var time = JSON.parse(create_time) * 1000
      var temp_date = new Date(time)
      return formatDate(temp_date, 'yyyy/MM/dd')
    },
    rank (number) {
      var tail = number.toString().charAt(number.toString().length-1,1)
      if (tail === '1' && number !== 11) {
        return "st";
      } else if (tail === '2' && number !== 12) {
        return "nd";
      } else if (tail === '3' && number !== 13) {
        return "rd";
      } else {
        return "th";
      }
    },
    upfirstName (name) {
        if (name) {
            const transferName = name.toLowerCase()
      return transferName.charAt(0).toUpperCase() + transferName.slice(1)     
        }else{
            return name
        }
      
    },
    exportPDF (id) {


        var style = document.createElement('style');

        if (id == 'showTotal'){
            // style.innerHTML = "@page{size:landscape 1528px 1080px;}";
            style.innerHTML = "@page{size:A4 landscape;}";
        }else{
            // style.innerHTML = "@page{size:portrait 2480px 3508px;margin: auto 0mm}";
            style.innerHTML = "@page{size:A4 portrait;margin: auto 0mm}";
            document.getElementsByTagName("body")[0].style.zoom = 1
        }

        window.document.head.appendChild(style)

        const printData = document.getElementById(id).innerHTML


          this.PageSetup_Null();//把页眉页脚设置为空


        window.document.body.innerHTML = printData // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
        window.print() // 开始打印
    },
    PageSetup_Null(){
        var HKEY_Root,HKEY_Path,HKEY_Key;

        HKEY_Root="HKEY_CURRENT_USER";

        HKEY_Path="\\Software\\Microsoft\\Internet Explorer\\PageSetup\\";

        try{
            var Wsh=new ActiveXObject("WScript.Shell");

            HKEY_Key="header";

            Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"");

            HKEY_Key="footer";

            Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"");

        }catch(e){}

        }

  }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'aleo-bold';
    src: url('../../assets/font/aleo-bold.ttf');
}
@font-face {
    font-family: 'Aleo-Bold-2';
    src: url('../../assets/font/Aleo-Bold-2.otf');
}
@font-face {
    font-family: 'Aleo-Italic-4';
    src: url('../../assets/font/Aleo-Italic-4.otf');
}
@font-face {
    font-family: 'Aleo-Light-5';
    src: url('../../assets/font/Aleo-Light-5.otf');
}
@font-face {
    font-family: 'Aleo-Regular-7';
    src: url('../../assets/font/Aleo-Regular-7.otf');
}
.certContainer {
  position: relative;
  color: rgb(42, 70, 154);
  .model {
    width: 297mm;
    height: 200.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 430px;
    left: 116px;
    font-weight: 500;
  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 525px;
    left: 250px;
    font-weight: 500;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }
   
  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 300px;
    left: 116px;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    .name {
      margin-right: 40px;
    }
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 260px;
    left: 116px;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  white;
  }
}
.certContainer1{
    position: relative;
    color: white;
    .model{
        width: 365.28mm;
        height: 205.47mm;
        z-index: -10;   
    }
    .event{
        position: absolute;
        font-size: 70px;
        top: 100px;
        left: 86px;
        font-weight: 300;
        width: 600px;
    }
    .award{
        position: absolute;
        font-size: 90px;
        top: 300px;
        left: 86px;
        font-weight: 600;
    }
    .student{
        position: absolute;
        width: 600px;
        top: 460px;
        left: 86px;
        font-size: 40px;
        font-style: italic;
        font-weight: 300;
        .name{
            margin-right: 40px;
            word-wrap: none;
        }
    }
    .school{
        position: absolute;
        top: 570px;
        left: 86px;
        font-size: 30px;
        font-weight: 300;
    }
}
.certContainer4{
  position: relative;
  color: rgb(42, 70, 154);
  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 430px;
    left: 116px;
    font-weight: 500;
  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 525px;
    left: 250px;
    font-weight: 500;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }

  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 300px;
    left: 116px;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    .name {
      margin-right: 40px;
    }
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 260px;
    left: 116px;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  white;
  }
}
.certContainerFelc{
  position: relative;
  color: #333;
  font-family: 'SourceHanSansCN-Bold';

  .model {
    width: 210mm;
    height: 295mm;
    z-index: 0;
  }
  .event {
    position: absolute;
    font-size: 25px;
    top: 550px;
    // left: 116px;
    font-weight: 600;
    text-align: center;
    width: 210mm;
  }
 
  .award {
    position: absolute;
    font-size: 38px;
    top: 645px;
    left: 105mm;
    font-weight: 500;
    color: #233C85;
  }
  .projectName{
    position: absolute;
    font-size: 20px;
    top: 505px;
    // left: 250px;
    text-align: center;
    width: 210mm;
    font-weight: 600;
  }
  .awardPh{
    font-family: 'SourceHanSansCN-Normal';
    position: absolute;
    font-size: 18px;
    top: 700px;
    left: 105mm;
    color: gray;
    font-weight: 400;
  }

  .tefaLab{
    position: absolute;
    font-size: 20px;
    top: 460px;
    font-weight: 600;
    text-align: center;
    width: 210mm;
    color: black;
  }
  .timeLab{
    font-family: 'SourceHanSansCN-Normal';
    position: absolute;
    font-size: 18px;
    top: 808px;
    left: 105mm;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 330px;
    // left: 116px;
    text-align: center;
    font-size: 35px;
    width: 210mm;
    // background-color: #003262;
    // font-style: italic;
    font-weight: 600;
    .name {
      // margin-right: 40px;
    }
  }
  .email{
    font-family: 'SourceHanSansCN-Normal';
    position: absolute;
    top: 380px;
    // left: 116px;
    width: 210mm;
    text-align: center;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    font-weight: 600;
    width: 210mm;
    text-align: center;
    top: 295px;
    // left: 116px;
    font-size: 23px;
    font-weight: 450;
  }
  .saiquImg{
    position: absolute;
    top: 620px;
    left: 250px;
    width: 140px;
    height: 130px;
    // background-color:  white;
  }
}

.transcriptContainer{
    position: relative;
    margin-bottom:-4px;
    .model_p{
        width: 210mm;
        height: 295mm;
        z-index: 0;
    }
    .name{
        position: absolute;
        top: 326px;
        left: 38px;
        font-size: 30px;
    
    }
    .info{
        position: absolute;
        top:384px;
        left: 38px;
        font-size: 15px;
        .tag{
            font-family: 'Aleo-Bold-2';
        }
        .content{
            font-family: 'Aleo-Regular-7';
        }
    }
    .score{
        position: absolute;
        top: 500px;
        width:320px;
        
        left:55px;
        font-size: 12px;
        font-family: 'Aleo-Regular-7';
        span{
            font-family: 'Aleo-Bold-2';
            font-size: 14px;
        }
        .title{
            font-family: 'aleo-bold';
        }
    }
    .score1{
        position: absolute;
        top: 500px;
        width:320px;
        left:434px;
        font-size: 15px;
        font-family: 'Aleo-Regular-7';
        span{
            font-family: 'Aleo-Bold-2';
            font-size: 17px;
        }
        .title{
            font-family: 'aleo-bold';
        }
    }
    .time1{
        position: absolute;
        top: 890px;
        width:320px;
        left:40px;
        font-size: 18px;
        font-weight: 600;
        color: #2A358A;
        // font-family: 'Aleo-Regular-7';
        // span{
        //     font-family: 'Aleo-Bold-2';
        //     font-size: 14px;
        // }
        // .title{
        //     font-family: 'aleo-bold';
        // }
    }
}

</style>
